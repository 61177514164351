import * as React from "react"
import FrontmatterPageContext from "~context/FakePageContext"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"

import LayoutCore from "~templates/layout-core"
import { mainArea, main } from "~vanilla/layout-default-full.css"

import { Box, H1 } from "@fastly/consistently"
import Alert from "~components/alert"

//# note: Legal must review changes to this doc. Consider this doc significant for changelog purposes.
import TranslationIndexContent from "~partials/translation-index.mdx"

const frontmatter = {
  section: "none",
  section_safe: "none",
  title: "Translations | Fastly Documentation",
  lang: "en"
}

const TranslationIndexPage = () => {

  const mdx_components = { H1, Alert, Box, Link }

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} style={{ justifySelf: "center" }} role="main">
              <div className="content-content">
                <Box justifySelf="center">
                  <MDXProvider components={mdx_components}>
                    <TranslationIndexContent />
                  </MDXProvider>
                </Box>
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default TranslationIndexPage

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <SeoHead frontmatter={frontmatter} {...props} />
    <FavHead />
  </>
}
